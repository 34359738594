import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "bootstrap";

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.css";
import "@/assets/scss/home.scss";
import "@/assets/scss/media.scss";

import "bootstrap/dist/js/bootstrap.min.js";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueClipboard from "vue-clipboard2";

createApp(App)
  .use(store)
  .use(router)
  .use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
  })
  .use(VueClipboard)
  .mount("#app");
